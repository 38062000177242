import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { KurzIconType } from 'src/app/custom/custom-configuration-modules/custom-icon-types-mapping.module';
import { KurzIconSize } from '../icon/icon.component';

@Component({
  selector: 'app-link-button',
  templateUrl: './link-button.component.html',
  styleUrls: ['./link-button.component.scss']
})
export class LinkButtonComponent implements OnInit {

  @Input()
  label: string;

  @Input()
  iconType: KurzIconType;

  @Input()
  iconSize: KurzIconSize;

  @Input()
  @HostBinding('class.app-link-disabled')
  disabled: boolean;

  @Input()
  @HostBinding('class.app-link-underlined')
  underlined: boolean;

  constructor() { }

  ngOnInit(): void {
  }

}
